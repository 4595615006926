import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import tickImg from "../images/banner-tick.svg"
import "../components/BannerIntro/BannerIntro.scss"

const ThanyouQuote = (props) => {
    
//var price = props?.location?.state?.price
var price =  parseFloat(props?.location?.state?.price.replace(/,/g, ""));
var stateprice = price * 0.0006
var address = props?.location?.state?.address
if(stateprice > 180 ) {
    stateprice = stateprice
} else {
    stateprice = '180';
}
return (
<React.Fragment>
<Header />
<div className="section-p banner-into-wrapper">
    <Container className="banner-container">
        <img src={tickImg} alt="banner-tick" className="banner-tick" />
    <div className="content">
        <h4 className="heading">YOUR QUOTE</h4>
        <h1 className="title">The quote for guaranteeing your property sale is £{stateprice.toLocaleString()}</h1>
        <div className="description"><p>The above is an indicative quote for the ClozeSure Guarantee to cover the sale of {address} with an agreed sale price of £{props?.location?.state?.price.toLocaleString()}.</p></div>
        <div className="cta-section">
            <Link to="/contact-clozesure/" className="btn btn-pink btn-primary">Contact us</Link>
            <Link to="/" className="btn btn-white btn-secondary">Maybe later</Link>
        </div>        
    </div>
    </Container>
</div>
    <Footer />
</React.Fragment>
)
}
export default ThanyouQuote;

